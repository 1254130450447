import * as autocheck from '@/modules/autocheck/services'
import * as utils from '@/utils/date.utils.ts'

import Mustache from 'mustache'
// eslint-disable-next-line import/no-webpack-loader-syntax
import autoCheckPdfString from '!!raw-loader!./AutoCheckFullPdf.html'
import { formatMoney } from 'accounting'
import { getBatchPdfs } from '@/services/PdfService'
import { formatDate } from '@/utils/date.utils.ts'

import store from '@/modules/auth/stores'
import { timeAgo } from '@/utils/date.utils.ts'

const DATE_FORMAT = 'yyyy-MM-dd'
const FALLBACK = 'N/A'

const mapGrid = grid => {
  const danger = ['stolen', 'risk', 'damage', 'scrapped', 'export']
  let pdfGrid = {}

  const getText = (key, value) => {
    if (key === 'stolen') return value ? 'STOLEN' : 'NOT STOLEN'
    if (key === 'risk') return value ? 'HIGH RISK' : 'NOT HIGH RISK'
    if (key === 'finance') return value ? 'FINANCE REPORTED' : 'NO FINANCE'
    if (key === 'damage') return value ? 'DAMAGE REPORTED' : 'NO DAMAGE'
    if (key === 'scrapped') return value ? 'SCRAPPED' : 'NOT SCRAPPED'
    if (key === 'plate') return value ? 'PLATE CHANGES' : 'NO PLATE CHANGES'
    if (key === 'export') return value ? 'EXPORTED' : 'NOT EXPORTED'
    if (key === 'import') return value ? 'IMPORTED' : 'NOT IMPORTED'
    if (key === 'extra') return value ? 'EXTRA DATA' : 'NO EXTRA DATA'
  }

  const getClass = (key, value) => {
    if (!value) return 'is-success'
    else if (key === 'plate')
      if (value === 1) return 'is-warning'
      else return 'is-danger'
    else if (danger.includes(key)) return 'is-danger'
    else return 'is-warning'
  }

  for (let [key, value] of Object.entries(grid)) {
    pdfGrid[key] = {
      class: getClass(key, value),
      text: getText(key, value)
    }
  }
  return pdfGrid
}

const mapStatus = status => {
  const getIcon = status => {
    if (status === 'Warning') return 'fas fa-exclamation-triangle'
    else if (status === 'Caution') return 'fas fa-question-circle'
    else return 'fas fa-check-square'
  }

  return {
    text: status.text.toUpperCase(),
    class: `is-${status.class}`,
    icon: getIcon(status.text)
  }
}

const mapSpecs = specs => {
  const { firstReg, powerOutputBhp: bhp, powerOutputKw: kw } = specs
  return {
    ...specs,
    vehicleAge: firstReg ? timeAgo(firstReg) : 'N/A',
    powerOutput: bhp && kw ? `${bhp}BHP, ${kw}KW` : 'N/A',
    firstReg: firstReg ? formatDate(firstReg, DATE_FORMAT) : 'N/A'
  }
}

const setGlassValue = (retailPriceCode, regex) => {
  if (retailPriceCode) {
    const newPrice = regex.exec(retailPriceCode)
    if (newPrice) {
      return formatMoney(newPrice[1], '£', 0)
    }
  }
  return 'N/A'
}

export const setDepreciation = percentOfOcn => {
  return percentOfOcn ? `${(100 - percentOfOcn).toFixed(2)}%` : FALLBACK
}

const setMileageWarnings = messageList => {
  const warnings = []
  messageList.forEach(message => {
    const id = message?.id
    if (id === '18') {
      warnings.push({ message: 'Some mileage records are out of sequence.' })
    }
    if (id === '19') {
      warnings.push({ message: 'The mileage entered is unexpectedly low.' })
    }
  })
  return warnings
}

export const checkMessages = (messageList, ids) => {
  let result = false
  messageList.forEach(message => {
    const messageId = message?.id
    if (ids.includes(messageId)) {
      result = true
    }
  })
  return result
}

export const getBatchPdf = pdfs => {
  const mappedPdfData = pdfs.map(pdf => getPdfMapping(pdf))
  getBatchPdfs(mappedPdfData, 'autocheck')
}

export const getPdfUser = () => {
  const { name, organisations } = store.state.user
  const branchId = organisations[0].organisation.id
  const organisationId = organisations[0].organisation.organisation?.id
  return { name, branchId, organisationId }
}

export const getPdfMapping = pdfDataObject => {
  const { request } = pdfDataObject
  const { response } = request.response
  const specs = autocheck.getSpec(response)
  const grid = autocheck.getGrid(response)
  const status = autocheck.getStatus(response)
  const percentOfOcn = autocheck.getPercentOfOcn(response)
  const user = getPdfUser()
  const firstReg = response?.vehicleRegistration?.ukDateFirstRegistered

  const pdfData = {
    vrm: request.vrm,
    vin: request.vin,
    fuelEstimate: response?.fuelEstimate || FALLBACK,
    mileage: request?.mileage || FALLBACK,
    mileageItems: response?.mileageDetails?.mileageItems || [],
    dgLevel: request?.dgLevel || FALLBACK,
    dgTerm: request?.dgTerm || FALLBACK,
    dgDate: formatDate(request?.createdAt, DATE_FORMAT) || FALLBACK,
    specs: mapSpecs(specs),
    previousKeepersItems: response?.keeperChangesDetails?.keeperChanges1 || [],
    plateChangeItems: response?.plateChangeDetails?.plateChanges1 || [],
    grid: mapGrid(grid),
    status: mapStatus(status),
    cO2Emissions: response?.vehicleExiseDutyData?.cO2Emissions || '000',
    userName: user.name,
    branchId: user.branchId,
    accountId: user.organisationId || '-',
    previousSearches: response?.previousSearchesDetails?.searchItemsCount,
    previousSearchItems: response?.previousSearchesDetails?.searchItems,
    highRiskItems: response?.highRiskRecordDetails?.highRiskItems,
    stolenVehicleDetails: response?.stolenVehicleDetails,
    colourChangeDetails: response?.colourChangesDetails?.colourChanges1,
    abiItems: autocheck.getCondition(
      response?.conditionData?.conditionDetailsItems
    ).list,
    financeItems: response?.finance?.financeDataRecords,
    cazana: response?.cazana,
    glass: response?.glassTradeValuationDetails,
    nivrm: response?.vehicleRegistration?.nivrm,
    newPrice: setGlassValue(
      response?.glassTradeValuationDetails?.retailPriceCode,
      /P(.*?)G/g
    ),
    glassCurrentRetail: setGlassValue(
      response?.glassTradeValuationDetails?.retailPriceCode,
      /R(.*?)$/g
    ),
    firstReg: firstReg,
    percentOfOcn: percentOfOcn,
    depreciation: setDepreciation(percentOfOcn),
    mileageWarnings: setMileageWarnings(
      response?.messageDetails?.messageList1 || []
    ),
    exported: checkMessages(response?.messageDetails?.messageList1, ['10']),
    exportedDate: response?.vehicleRegistration.dateExported
      ? formatDate(response.vehicleRegistration.dateExported, DATE_FORMAT)
      : null,
    imported: checkMessages(response?.messageDetails?.messageList1, [
      '22',
      '16'
    ]),
    plateChange: checkMessages(response?.messageDetails?.messageList1, ['85']),
    noRegAlert: checkMessages(response?.messageDetails?.messageList1, ['29']),
    additionalDVLAData: checkMessages(
      response?.messageDetails?.messageList1,
      '75'
    ),
    scrapped: checkMessages(response?.messageDetails?.messageList1, ['09']),
    scrappedDate: response?.conditionData?.conditionDetailsItems[0]?.lossDate,
    reportDate: utils.formatDate(request.createdAt),
    reportTime: formatDate(request.createdAt, 'H:mm:ss'),
    formatTime: function() {
      return function(text, render) {
        return [
          render(text).slice(1, 3),
          ':',
          render(text).slice(3, 5),
          ':',
          render(text).slice(5, 7)
        ].join('')
      }
    },
    formatDate: function() {
      return function(text, render) {
        try {
          return utils.formatDate(render(text))
        } catch {
          return ''
        }
      }
    },
    formatMoney: function() {
      return function(text, render) {
        return formatMoney(render(text), '£', 0)
      }
    },
    FALLBACK
  }

  const pdfString = Mustache.render(autoCheckPdfString, pdfData)
  const pdfName = `Percayso (PVI) - ${request.vrm} - Autocheck Certificate`

  return {
    pdfString,
    vrm: request.vrm,
    pdfName
  }
}
