import { camelCase } from 'lodash'

import Mustache from 'mustache'
import Vue from 'vue'
// eslint-disable-next-line import/no-webpack-loader-syntax
import autoCheckPdfString from '!!raw-loader!./FranchisePdf.html'
import { checkMessages } from '../'
import { formatDate } from '@/utils/date.utils.ts'
import * as utils from '@/utils/date.utils.ts'
import { node } from '@/plugins/axios'

const warningCheck = pdfData => {
  const { finance, stolen, risk, imported, scrapped } = pdfData
  const warningArray = [finance, stolen, risk, imported, scrapped]
  return warningArray.some(e => e)
}

export const getFranchisePdf = (franchise, storeData) => {
  const { specs, grid, response } = storeData
  const plateChangeItems = response?.plateChangeDetails?.plateChanges1 || null
  const pdfData = {
    colour: franchise.colour,
    date: formatDate(new Date(), 'do MMMM yyyy'),
    time: formatDate(new Date(), 'p'),
    vrm: response.vehicleRegistration.vrm,
    imgUrl: `https://s3.eu-west-2.amazonaws.com/fuel-economy-pdf-images/${camelCase(
      franchise.name
    )}.png`,
    vin: response.vehicleRegistration.vinSerialNumber,
    specs,
    finance: grid.finance,
    stolen: grid.stolen,
    scrapped: grid.scrapped,
    risk: grid.risk,
    imported: checkMessages(response?.messageDetails?.messageList1, [
      '22',
      '16'
    ]),
    abiItems: response?.conditionData?.conditionDetailsItems,
    scrappedDate: response?.conditionData?.conditionDetailsItems[0]?.lossDate,
    plateChangeItems,
    footerPadding: 140 - plateChangeItems.length * 10,
    formatDate: function() {
      return function(text, render) {
        return utils.formatDate(render(text))
      }
    }
  }
  pdfData.warning = warningCheck(pdfData)
  const pdfString = Mustache.render(autoCheckPdfString, pdfData)
  const pdfName = `Percayso (PVI) - ${response.vehicleRegistration.vrm} - Autocheck Franchise Certificate`

  return {
    pdfString,
    vrm: response.vehicleRegistration.vrm,
    pdfName,
  }
}

const notify = params => Vue.prototype.$notify(params)

export const emailPdf = async (certificate, emailTo) => {
  try {
    await node.post('/autocheck/franchises/email', {
      certificate,
      emailTo
    })
  } catch {
    notify({ group: 'danger', text: 'Request unsuccessful' })
  }
}
