// eslint-disable-next-line import/no-webpack-loader-syntax
import fuelPdfString from '!!raw-loader!./FuelEconomyReport.html'
import { getBatchPdfs } from '@/services/PdfService'
import store from '@/store'
import template from 'es6-template-strings'

export const getFuelEconomyPdf = () => {
  const {
    vehicleExiseDutyData,
    vehicleRegistration,
    performanceAndConsumptionData
  } = store.getters['autocheck/response']
  const fuelCost = store.getters['autocheck/fuelCost']
  const fuelPrices = store.getters['autocheck/fuelPrices']

  const fuelEconomyData = {
    vrm: vehicleRegistration.vrm,
    co2Band: vehicleExiseDutyData.cO2Band,
    co2Emission: vehicleExiseDutyData.cO2Emissions,
    dateUpdated: fuelPrices.dateUpdated,
    petrolPrice: fuelPrices.petrol,
    dieselPrice: fuelPrices.diesel,
    lpgPrice: fuelPrices.lpg,
    fuelCost: fuelCost,
    vehicle: {
      ved: vehicleExiseDutyData.veD12Months,
      make: vehicleRegistration.make,
      model: vehicleRegistration.model,
      fuelType: vehicleRegistration.fuel,
      engineCapacity: vehicleRegistration.engineCapacity,
      transmission: vehicleRegistration.transmission,
      firstRegisteredInUk: vehicleRegistration.dateFirstRegistered.slice(0, 10)
    },
    urbanColdLkm: performanceAndConsumptionData.urbanColdLkm,
    extraUrbanLkm: performanceAndConsumptionData.extraUrbanLkm,
    combinedLkm: performanceAndConsumptionData.combinedLkm,
    urbanColdMpg: performanceAndConsumptionData.urbanColdMpg,
    extraUrbanMpg: performanceAndConsumptionData.extraUrbanMpg,
    combinedMpg: performanceAndConsumptionData.combinedMpg,
    arrowHeight: setArrowHeight(vehicleExiseDutyData.cO2Band),
    reportCreateDate: '',
    reportCreatedTime: '',
    newVehicleDisclaimer:
      Date.parse(vehicleRegistration.dateFirstRegistered) >=
      Date.parse('2017-04-01')
        ? 'Cars with a list price of over &pound;40,000 when new pay an additional rate of &pound;310 per year on top of the standard rate, for five years.'
        : ''
  }
  const fuelPdfStringWithData = template(fuelPdfString, fuelEconomyData)
  const pdfName = `Percayso (PVI) - ${vehicleRegistration.vrm} - Fuel Economy Certificate`

  return {
    pdfString: fuelPdfStringWithData,
    vrm: vehicleRegistration.vrm,
    pdfName,
  }
}

const setArrowHeight = cO2Band => {
  switch (cO2Band) {
    case 'A':
      return 28
    case 'B':
    case 'C':
      return 88
    case 'D':
    case 'E':
      return 150
    case 'F':
    case 'G':
      return 211
    case 'H':
    case 'I':
      return 272
    case 'J':
    case 'K':
      return 333
    case 'L':
    case 'M':
      return 392
    default:
      break
  }
}

export const downloadFuelEconomyPdf = () => {
  const pdf = getFuelEconomyPdf()
  getBatchPdfs([pdf], store.getters['autocheck/vrm'], 'file')
}
